<template>
  <p class="clearfix mb-0">
    <b-row>
      <b-col cols="6" class="d-flex align-items-center pl-3">
        <b-link
          class="ml-25"
          href="https//ninhbinh.vnpt.vn"
          target="_blank"
        >{{ appBrandName }}</b-link>
        © 2022 - {{ new Date().getFullYear() }}
      </b-col>
      <b-col cols="6" class="d-flex justify-content-end pr-3 position-relative">
        <div>
          <span>
            <div class="footer-qrcode-ios">
              <span>
                IOS
              </span>
              <span>
                <b-link href="https://apps.apple.com/vn/app/id6711345740?platform=iphone" target="blank">
                  <b-img
                    :src="require('@/assets/images/pages/vnpt_qrcode_ios_bachnghe.png')"
                    height="auto"
                    width="12"
                    class="img-fluid"
                    alt="IOS QR"
                    style="width: 60px;"
                  />
                </b-link>
              </span>
            </div>
            <div class="footer-qrcode-android">
              <span>
                Android
              </span>
              <span>
                <b-link href="https://play.google.com/store/apps/details?id=com.vnpt.uni.bachnghe" target="blank">
                  <b-img
                    :src="require('@/assets/images/pages/vnpt_qrcode_android_bachnghe.png')"
                    height="auto"
                    width="12"
                    class="img-fluid"
                    alt="Android QR"
                    style="width: 60px;"
                  />
                </b-link>
              </span>
            </div>
            <b-img
              height="35"
              :src="imgSrc"
              alt="logo"
          />
          </span>
        </div>
      </b-col>
    </b-row>
  </p>
</template>

<script>
import {  
  BLink,
  BImg,
  BRow,
  BCol, 
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BImg,
    BRow,
    BCol,
  },
  data() {
    return {
      appBrandName: process.env.VUE_APP_BRAND_NAME,
    }
  },
  computed: {
    imgSrc() {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/images/logo/${process.env.VUE_APP_LOGO_FOOTER}`)
    },
  },
}
</script>

<style lang="scss">
  [dir] footer.footer {
    padding: 0;
  }

  .footer-qrcode-ios {
    position: absolute;
    bottom: 0;
    right: 340px;
    z-index: 100;
    font-weight: 900;
    margin-bottom: -7px;
  }

  .footer-qrcode-android {
    position: absolute;
    bottom: 0;
    right: 200px;
    z-index: 100;
    font-weight: 900;
    margin-bottom: -7px;
  }
</style>
